/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { BASE_URL } from 'config';
import {
	CLEAR_DASHBOARD,
	GET_BUSINESS_INFO,
	GET_CLIENT,
	GET_CONTRACT,
	GET_CONTRACT_REUPLOAD,
	GET_KYC_DOC,
	LOG_OUT,
	REMOVE_CONTRACT,
	UPLOAD_CONTRACT,
	SET_CLIENT_LIST,
	SET_ADVANCE_LIST,
	SET_LOGS_LIST,
	SET_REPAYMENT_LIST,
	UPDATE_CLIENT_LIST,
	UPDATE_ADVANCE_LIST,
	UPDATE_LOGS_LIST,
	UPDATE_REPAYMENT_LIST,
	SET_LEAD_LIST,
	UPDATE_LEAD_LIST,
	SET_CSV_LIST,
	UPDATE_CSV_LIST,
	SET_REPAYMENT_CLIENT_LIST,
	UPDATE_REPAYMENT_CLIENT_LIST,
	SET_CLIENT_OFFER,
	UPDATE_CLIENT_OFFER,
	SET_MANUAL_REPAYMENT_CLIENT_LIST,
	UPDATE_MANUAL_REPAYMENT_CLIENT_LIST,
	UPDATE_MANUAL_REPAYMENT_LIST,
	SET_MANUAL_REPAYMENT_LIST,
	SET_ROLE_LIST,
	UPDATE_ROLE_LIST,
	SET_USERS_LIST,
	UPDATE_USERS_LIST,
} from '../types';
import { store } from '../index';
import { Contract } from 'dto/redux.action.dto';
import { Dispatch } from 'redux';

export const uploadContract = (contract: Contract) => {
	return {
		type: UPLOAD_CONTRACT,
		payload: contract,
	};
};

export const setClientList = (query: string) => async (dispatch: Dispatch) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.get(`${BASE_URL}client/list?${query}`, options);
		return dispatch({
			type: SET_CLIENT_LIST,
			payload: {
				page: data.page,
				total: data.totalDocs,
				data: data.docs,
				loading: false,
			},
		});
	} catch (err) {
		if (err?.response?.status === 401)
			return dispatch({
				type: LOG_OUT,
			});
		else {
			return dispatch({ type: null });
		}
	}
};

export const updateClientList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_CLIENT_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const setLogsList = (query: string) => async (dispatch: Dispatch) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.get(
			`${BASE_URL}event-log/list?${query}`,
			options
		);
		return dispatch({
			type: SET_LOGS_LIST,
			payload: {
				page: data.page,
				total: data.totalDocs,
				data: data.docs,
				loading: false,
			},
		});
	} catch (err) {
		if (err?.response?.status === 401)
			return dispatch({
				type: LOG_OUT,
			});
		else {
			return dispatch({ type: null });
		}
	}
};

export const updateLogsList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_LOGS_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const getClient = (clientId: string) => async (dispatch: Dispatch) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const res = await axios.get(`${BASE_URL}client/info/${clientId}`, options);
		return dispatch({
			type: GET_CLIENT,
			payload: res.data,
		});
	} catch (err) {
		if (err?.response?.status === 401)
			return dispatch({
				type: LOG_OUT,
			});
		else {
			return dispatch({ type: null });
		}
	}
};

export const getClientDoc = (clientId: string) => async (dispatch: any) => {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const body = {
			clientId: clientId,
			fileType: 'contract',
		};
		const res = await axios.post(`${BASE_URL}file`, body, options);
		return dispatch({
			type: GET_CONTRACT,
			payload: res.data,
		});
	} catch (err) {
		if (err?.response?.status === 401) {
			return dispatch({
				type: LOG_OUT,
			});
		}
		return dispatch({
			type: 'Error',
		});
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getClientKYCDoc = (clientId: string) => async (dispatch: any) => {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const body = {
			clientId: clientId,
			fileType: 'KYC',
		};
		const res = await axios.post(`${BASE_URL}file`, body, options);
		return dispatch({
			type: GET_KYC_DOC,
			payload: res.data,
		});
	} catch (err) {
		if (err?.response?.status === 401) {
			return dispatch({
				type: LOG_OUT,
			});
		}
		return dispatch({
			type: 'Error',
		});
	}
};

export const getClientBusinessInfo =
	(clientId: string) => async (dispatch: Dispatch) => {
		try {
			const options = {
				headers: {
					Authorization: `Bearer ${store.getState().auth.accessToken}`,
					'Content-Type': 'application/json',
				},
			};
			const body = {
				clientId: clientId,
				fileType: 'businessInfo',
			};
			const res = await axios.post(`${BASE_URL}file`, body, options);
			return dispatch({
				type: GET_BUSINESS_INFO,
				payload: res.data,
			});
		} catch (err) {
			if (err?.response?.status === 401) {
				return dispatch({
					type: LOG_OUT,
				});
			}
			return dispatch({
				type: 'Error',
			});
		}
	};

export const getContractReUpload =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(clientId: string) => async (dispatch: any) => {
		try {
			const options = {
				headers: {
					Authorization: `Bearer ${store.getState().auth.accessToken}`,
					'Content-Type': 'application/json',
				},
			};
			const body = {
				clientId: clientId,
				fileType: 'contractReupload',
			};
			const res = await axios.post(`${BASE_URL}file`, body, options);
			return dispatch({
				type: GET_CONTRACT_REUPLOAD,
				payload: res.data,
			});
		} catch (err) {
			if (err?.response?.status === 401) {
				return dispatch({
					type: LOG_OUT,
				});
			}
			return dispatch({
				type: 'Error',
			});
		}
	};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateContract = (offerId: string) => async (dispatch: any) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	const data = {
		// eslint-disable-next-line no-underscore-dangle
		id: store.getState().dash.contract._id,
		offerId: offerId,
	};
	try {
		await axios.patch(`${BASE_URL}file/update`, data, options);
		return dispatch({
			type: 'Reset Offer',
		});
	} catch (err) {
		if (err?.response?.status === 401) {
			return dispatch({
				type: LOG_OUT,
			});
		}
		return dispatch({
			type: 'Error',
		});
	}
};

export const removeContract = () => {
	return {
		type: REMOVE_CONTRACT,
	};
};

export const clearDashboard = () => {
	return {
		type: CLEAR_DASHBOARD,
	};
};

export const setAdvanceList = (query: string) => async (dispatch: Dispatch) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.get(`${BASE_URL}advance/list?${query}`, options);
		return dispatch({
			type: SET_ADVANCE_LIST,
			payload: {
				page: data.page,
				total: data.totalDocs,
				data: data.docs,
				loading: false,
			},
		});
	} catch (err) {
		if (err?.response?.status === 401)
			return dispatch({
				type: LOG_OUT,
			});
		else {
			return dispatch({ type: null });
		}
	}
};

export const updateAdvanceList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_ADVANCE_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const setRepaymentList =
	(query: string) => async (dispatch: Dispatch) => {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		try {
			const { data } = await axios.get(
				`${BASE_URL}revenue/list?${query}`,
				options
			);
			return dispatch({
				type: SET_REPAYMENT_LIST,
				payload: {
					page: data.page,
					total: data.totalDocs,
					data: data.docs,
					loading: false,
				},
			});
		} catch (err) {
			if (err?.response?.status === 401)
				return dispatch({
					type: LOG_OUT,
				});
			else {
				return dispatch({ type: null });
			}
		}
	};
export const setRepaymentListClient =
	(query: string, id: string) => async (dispatch: Dispatch) => {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		try {
			const { data } = await axios.get(
				`${BASE_URL}revenue/list/${id}?${query}`,
				options
			);
			return dispatch({
				type: SET_REPAYMENT_CLIENT_LIST,
				payload: {
					page: data.page,
					total: data.totalDocs,
					data: data.docs,
					loading: false,
				},
			});
		} catch (err) {
			if (err?.response?.status === 401) {
				return dispatch({
					type: LOG_OUT,
				});
			}
			return null;
		}
	};

export const updateRepaymentClientList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_REPAYMENT_CLIENT_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const setManualRepaymentList =
	(query: string) => async (dispatch: Dispatch) => {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		try {
			const { data } = await axios.get(
				`${BASE_URL}manual-transfer/list/all?${query}`,
				options
			);
			return dispatch({
				type: SET_MANUAL_REPAYMENT_LIST,
				payload: {
					page: data.page,
					total: data.totalDocs,
					data: data.docs,
					loading: false,
				},
			});
		} catch (err) {
			if (err?.response?.status === 401) {
				return dispatch({
					type: LOG_OUT,
				});
			}
			return null;
		}
	};

export const updateManualRepaymentList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_MANUAL_REPAYMENT_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const setManualRepaymentListClient =
	(query: string, id: string) => async (dispatch: Dispatch) => {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		try {
			const { data } = await axios.get(
				`${BASE_URL}manual-transfer/list/${id}?${query}`,
				options
			);
			return dispatch({
				type: SET_MANUAL_REPAYMENT_CLIENT_LIST,
				payload: {
					page: data.page,
					total: data.totalDocs,
					data: data.docs,
					loading: false,
				},
			});
		} catch (err) {
			if (err?.response?.status === 401) {
				return dispatch({
					type: LOG_OUT,
				});
			}
			return null;
		}
	};

export const updateManualRepaymentClientList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_MANUAL_REPAYMENT_CLIENT_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const updateRepaymentList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_REPAYMENT_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const setClientOfferData =
	(query: string, id: string) => async (dispatch: Dispatch) => {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		try {
			const { data } = await axios.get(
				`	${BASE_URL}offer/list/${id}
				`,
				options
			);
			return dispatch({
				type: SET_CLIENT_OFFER,
				payload: {
					page: data.page,
					total: data.totalDocs,
					data: data.docs,
					loading: false,
				},
			});
		} catch (err) {
			if (err?.response?.status === 401)
				return dispatch({
					type: LOG_OUT,
				});
			else {
				return dispatch({ type: null });
			}
		}
	};

export const updateClientOfferData =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_CLIENT_OFFER,
			payload: {
				key,
				value,
			},
		});
	};

export const setLeadsList = (query: string) => async (dispatch: Dispatch) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.get(`${BASE_URL}visitor/list?${query}`, options);
		return dispatch({
			type: SET_LEAD_LIST,
			payload: {
				page: data.page,
				total: data.totalDocs,
				data: data.docs,
				loading: false,
			},
		});
	} catch (err) {
		if (err?.response?.status === 401)
			return dispatch({
				type: LOG_OUT,
			});
		else {
			return dispatch({ type: null });
		}
	}
};

export const updateLeadsList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_LEAD_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const setCSVList = (query: string) => async (dispatch: Dispatch) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.get(`${BASE_URL}report/list?${query}`, options);
		return dispatch({
			type: SET_CSV_LIST,
			payload: {
				page: data.page,
				total: data.totalDocs,
				data: data.docs,
				loading: false,
			},
		});
	} catch (err) {
		if (err?.response?.status === 401)
			return dispatch({
				type: LOG_OUT,
			});
		else {
			return dispatch({ type: null });
		}
	}
};

export const updateCSVList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_CSV_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const setRoleList = (query: string) => async (dispatch: Dispatch) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.get(
			`${BASE_URL}admin/role/list?${query}`,
			options
		);
		return dispatch({
			type: SET_ROLE_LIST,
			payload: {
				page: 1,
				total: 1,
				data: data,
				loading: false,
			},
		});
	} catch (err) {
		if (err?.response?.status === 401)
			return dispatch({
				type: LOG_OUT,
			});
		else {
			return dispatch({ type: null });
		}
	}
};

export const updateRoleList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_ROLE_LIST,
			payload: {
				key,
				value,
			},
		});
	};

export const setUserList = (query: string) => async (dispatch: Dispatch) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const { data } = await axios.get(
			`${BASE_URL}admin/user/list?${query}`,
			options
		);
		return dispatch({
			type: SET_USERS_LIST,
			payload: {
				page: data.page,
				total: data.totalDocs,
				data: data.docs,
				loading: false,
			},
		});
	} catch (err) {
		if (err?.response?.status === 401)
			return dispatch({
				type: LOG_OUT,
			});
		else {
			return dispatch({ type: null });
		}
	}
};

export const updateUserList =
	(key: string, value: any) => async (dispatch: Dispatch) => {
		return dispatch({
			type: UPDATE_USERS_LIST,
			payload: {
				key,
				value,
			},
		});
	};

// setUsersList
// updateUsersList
