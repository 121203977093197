/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { message } from 'antd';
import axios from 'axios';
import { store } from '..';
import { BASE_URL } from '../../config';
import * as publicIp from 'public-ip';
import * as Sentry from '@sentry/nextjs';
import {
	BAD_REQ,
	ERROR,
	GET_CLIENT,
	LOG_IN,
	LOG_OUT,
	REMOVE_HASH,
	SET_LOADING,
	STORE_LOGIN_INFO,
	UPDATE_OTP_TIMER,
} from '../types';

export const handleLogin =
	(email: string, password: string) => async (dispatch: any) => {
		const data = { email, password };
		try {
			setLoading(true, dispatch);
			const result = await axios.post(`${BASE_URL}admin/login`, data);
			setLoading(false, dispatch);
			if (result.status === 200) {
				return dispatch({
					type: STORE_LOGIN_INFO,
					payload: { hashKey: result.data.hashKey, email: email },
				});
			}
		} catch (err) {
			Sentry.captureException(err);
			Sentry.captureMessage('authAction -> handleLogin', err);

			setLoading(false, dispatch);
			if (err?.response?.status === 401) {
				message.error('Authorization Failed');
			} else if (err?.response?.status === 400) {
				message.error('Please insert all the required field');
				try {
					return dispatch({
						type: BAD_REQ,
					});
				} catch (error) {
					return dispatch({
						type: ERROR,
						payload: error,
					});
				}
			}
		}
	};

export const updateOtpTimer = (second: number) => {
	return {
		type: UPDATE_OTP_TIMER,
		payload: second,
	};
};

export const removeHash = () => async (dispatch: any) => {
	return dispatch({
		type: REMOVE_HASH,
	});
};

export const setLoading = (status: boolean, dispatch: any) => {
	dispatch({
		type: SET_LOADING,
		payload: status,
	});
};

export const submitLogin = (otp: string) => async (dispatch: any) => {
	const { hashKey } = store.getState().auth;

	const { email } = store.getState().auth;
	const data = {
		sendTo: email,
		hash: hashKey,
		otp: otp,
		email: email,
		ip: await publicIp.v4(),
	};

	try {
		setLoading(true, dispatch);
		const result = await axios.post(`${BASE_URL}admin/login/verify-otp`, data);
		setLoading(false, dispatch);
		dispatch({
			type: LOG_IN,
			payload: result.data,
		});
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> submitLogin', err);

		message.error(err.response.message);
		return dispatch({
			type: LOG_OUT,
		});
	}
};

export const logOut = () => async (dispatch: any) => {
	return dispatch({
		type: LOG_OUT,
	});
};

export const getClient = () => async (dispatch: any) => {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const res = await axios.get(
			`${BASE_URL}client/${store.getState().auth.loginData.clientId}`,
			options
		);
		return dispatch({
			type: GET_CLIENT,
			payload: res.data,
		});
	} catch (err) {
		Sentry.captureException(err);
		Sentry.captureMessage('authAction -> getClient', err);

		if (err.response.data.statusCode === 403) {
			return dispatch({
				type: LOG_OUT,
			});
		}
	}
};
